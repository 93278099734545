import { TemplateStatus, TemplateType } from '@piccolohealth/pbs-common';
import { arrayOfParam, stringParam, useDebouncedCallback, useQueryParams } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';

const DEFAULT_NAME_FILTER = '';
const DEFAULT_STATUS_FILTER: TemplateStatus[] = [];
const DEFAULT_TYPE_FILTER: TemplateType[] = [];
const DEFAULT_LABEL_FILTER: string[] = [];

export interface TemplatesFilter {
  nameFilter: string;
  statusFilter: TemplateStatus[] | undefined;
  typeFilter: TemplateType[] | undefined;
  labelFilter: string[] | undefined;
  activeFilterCount: number;
  onNameFilter: (name: string) => void;
  onStatusFilter: (statuses?: TemplateStatus[]) => void;
  onTypeFilter: (types?: TemplateType[]) => void;
  onLabelFilter: (labels?: string[]) => void;
  reset: () => void;
}

export const useTemplatesFilter: () => TemplatesFilter = () => {
  const [params, setParams] = useQueryParams({
    name: stringParam,
    status: arrayOfParam(stringParam, { delimiter: ',' }),
    type: arrayOfParam(stringParam, { delimiter: ',' }),
    label: arrayOfParam(stringParam, { delimiter: ',' }),
  });

  const onNameFilter = useDebouncedCallback(
    (value: string) =>
      setParams({
        name: value,
      }),
    1000,
  );

  const onStatusFilter = (statuses?: TemplateStatus[]) =>
    setParams({
      status: statuses,
    });

  const onTypeFilter = (types?: TemplateType[]) =>
    setParams({
      type: types,
    });

  const onLabelFilter = (labels?: string[]) =>
    setParams({
      label: labels,
    });

  const reset = () => {
    setParams({
      name: DEFAULT_NAME_FILTER,
      status: DEFAULT_STATUS_FILTER,
      type: DEFAULT_TYPE_FILTER,
      label: DEFAULT_LABEL_FILTER,
    });
  };

  const activeFilterCount = P.compact([
    params.name,
    params.status,
    params.type,
    params.label,
  ]).length;

  return {
    nameFilter: params.name ?? DEFAULT_NAME_FILTER,
    statusFilter: (params.status as TemplateStatus[]) ?? DEFAULT_STATUS_FILTER,
    typeFilter: (params.type as TemplateType[]) ?? DEFAULT_TYPE_FILTER,
    labelFilter: (params.label as string[]) ?? DEFAULT_LABEL_FILTER,
    activeFilterCount,
    onNameFilter,
    onStatusFilter,
    onTypeFilter,
    onLabelFilter,
    reset,
  };
};
