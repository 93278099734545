import { MenuItem, ToastId } from '@chakra-ui/react';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { useExportParticipantsCsvMutation } from '../../graphql/hooks/useExportParticipantsMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantsFilter } from '../../hooks/useParticipantsFilter';
import { usePermission } from '../../hooks/usePermission';

interface Props {
  filter: ParticipantsFilter;
}

export const ParticipantExportCsvMenuItem = (props: Props) => {
  const { filter } = props;
  const { organization, toast, errorToast } = useAppContext();

  const toastRef = React.useRef<ToastId>();

  const closeToast = React.useCallback(() => {
    if (toastRef.current) {
      toast.close(toastRef.current);
    }
  }, [toast]);

  const finishToast = React.useCallback(() => {
    if (toastRef.current) {
      toast.update(toastRef.current, {
        title: 'CSV successfully generated',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top',
      });
    }
  }, [toast]);

  const startToast = React.useCallback(() => {
    toastRef.current = toast({
      title: 'Generating participants CSV...',
      isClosable: true,
      duration: null,
      position: 'top',
    });
  }, [toast]);

  const hasPermission = usePermission('read', 'participant');

  const mutation = useExportParticipantsCsvMutation();

  const onClick = React.useCallback(async () => {
    startToast();

    await mutation
      .mutateAsync({
        organizationId: organization.id,
        request: {
          start: filter.startDateFilter,
          end: filter.endDateFilter,
          ndisNumber: filter.ndisNumberFilter,
          label: filter.labelFilter,
          location: filter.locationFilter,
          assignedTo: filter.assignedToFilter,
          name: filter.nameFilter,
          status: filter.statusFilter,
        },
      })
      .then((res) => {
        finishToast();

        res && window.open(res.exportParticipantsCsv, '_self');
      })
      .catch((err) => {
        closeToast();
        errorToast(`Error exporting participants: ${err.message}`);
      });
  }, [startToast, mutation, organization.id, filter, closeToast, finishToast, errorToast]);

  return (
    <MenuItem isDisabled={!hasPermission} onClick={onClick} icon={<FaDownload />}>
      Export participants (CSV)
    </MenuItem>
  );
};
